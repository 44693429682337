import { NextPage, NextPageContext } from 'next';
import Link from 'next/link';
import Image from 'next/image';
import * as Typography from '@/components/shadcn/typography';
import { BasicLayout } from '@/components/layout/Layout';
import { Button } from '@/components/shadcn/button';

export interface Props {
  statusCode?: number;
}

const Custom403: React.FC = () => (
  <>
    <Typography.H1>403</Typography.H1>
    {"Sorry, you don't have permissions to access this page."}
    <Link href="/network/coverage">
      <Button>Go Home</Button>
    </Link>
  </>
);

const Custom404: React.FC = () => (
  <>
    <Typography.H1>404</Typography.H1>
    The page you are trying to reach does not exist.
    <Link href="/network/coverage">
      <Button>Go Home</Button>
    </Link>
  </>
);

const Custom429: React.FC = () => (
  <>
    <Typography.H1>429</Typography.H1>
    Too many requests.
    <Link href="/network/coverage">
      <Button>Go Home</Button>
    </Link>
  </>
);

const Custom500: React.FC = () => (
  <>
    <Typography.H1>500</Typography.H1>
    Sorry, the service is temporarily unavailable.
  </>
);

const UnhandledError: React.FC = () => (
  <>
    <Typography.H1>Unhandled Error</Typography.H1>
    Sorry, an unhandled error has occured.
  </>
);

const getErrorPageComponent = (statusCode?: number) => {
  switch (statusCode) {
    case 403:
      return <Custom403 />;
    case 404:
      return <Custom404 />;
    case 429:
      return <Custom429 />;
    case 500:
      return <Custom500 />;
    default:
      return <UnhandledError />;
  }
};

const ErrorPage: NextPage<Props> = ({ statusCode }) => (
  <BasicLayout>
    <div className="flex size-full flex-col items-center justify-center gap-6">
      <div className="max-w-[290px]"></div>
      {getErrorPageComponent(statusCode)}
      <Link href="/network/coverage">
        <Image
          src="https://hivemapper-static.s3.us-west-2.amazonaws.com/bee/beemaps-logo.svg"
          width={212}
          height={32}
          priority
          alt="logo"
        />
      </Link>
    </div>
  </BasicLayout>
);

ErrorPage.getInitialProps = async ({ res, err }: NextPageContext) => {
  const statusCode = res ? res.statusCode : err ? err.statusCode : 404;
  return { statusCode };
};

export default ErrorPage;
